'use client';

import {
  type LayoutV3Props as UiPageLayoutProps,
  PageLayoutV3 as UiPageLayout,
  SimpleFooterProps,
  TypographyV3 as Typography,
} from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import { PropsWithChildren } from 'react';

import usePageView from '@/analytics/hooks/usePageView';
import { type ThemeConfig } from '@/configs/theme';

import useLegalLinks from './useLegalLinks';

export interface PageLayoutProps {
  readonly className?: string;
  readonly hideSocialLinks?: SimpleFooterProps['hideSocialLinks'];
  readonly name?: string;
  readonly theme?: ThemeConfig;
  readonly padding?: UiPageLayoutProps['containerProps']['padding'];
  readonly pageName: string;
}

const PageLayout = ({
  children,
  className,
  hideSocialLinks,
  pageName,
  name = 'Enrollment Order Portal',
  theme = {},
  padding = 'bottom',
}: PropsWithChildren<PageLayoutProps>): JSX.Element => {
  const legalLinks = useLegalLinks();

  usePageView(pageName);

  return (
    <UiPageLayout
      className={cx(theme.bgColorClass, className)}
      containerProps={{ name, padding }}
      footerProps={{
        variant: 'simple',
        hideSocialLinks,
        legalLinks,
      }}
      headerProps={{} as UiPageLayoutProps['headerProps']}
      hideHeader={true}
      mainLabel="main-content"
    >
      {/* Typography wrapper sets default text color */}
      <Typography
        Element="div"
        color={theme.typographyColor}
        data-testid="page-layout"
        className="grow"
      >
        {children}
      </Typography>
    </UiPageLayout>
  );
};

export default PageLayout;
