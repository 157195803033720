export const IMAGE_SRC_PREFIXES = [
  'https://s3.amazonaws.com/ouraring.com/images',
  'https://ourahealth.imgix.net',
];

export const IMAGE_FORMATS = [
  'jpg',
  'jpeg',
  'gif',
  'png',
  'svg',
  'webp',
] as const;
