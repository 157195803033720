'use client';

import {
  GridItem,
  LayoutGrid,
  TypographyV3 as Typography,
} from '@jouzen/ecom-components';
import { type ReactNode } from 'react';

import { Image, ImageProps } from '@/app/components/ui';

import NotFound from '../../ui/EmailForm/NotFound';
import EmailContent from './EmailContent';

interface EmailLayoutProps {
  readonly title: ReactNode;
  readonly description: ReactNode;
  readonly help?: ReactNode;
  readonly imageSrc: ImageProps['src'];
  readonly partner: string;
  readonly isInvalid: boolean;
  readonly isMaintenance: boolean;
}

const EmailLayout = ({
  title,
  description,
  help,
  imageSrc,
  partner,
  isInvalid,
  isMaintenance,
}: EmailLayoutProps): JSX.Element => {
  return (
    <LayoutGrid className="w-full sm:pt-10 lg:pt-20" rowGap={40}>
      <GridItem
        rowStart={{ sm: 2, md: 1 }}
        rowEnd={{ sm: 3 }}
        colStart={{ sm: 'main', md: 15, lg: 16 }}
        colEnd={{ sm: 'main', xl: 23 }}
        className="flex flex-col gap-4"
      >
        <Typography Element="h4" variant="h4">
          {title}
        </Typography>

        <Typography variant="body-large">{description}</Typography>

        {!isMaintenance &&
          (isInvalid ? <NotFound /> : <EmailContent partner={partner} />)}

        {help && <div className="mt-8">{help}</div>}
      </GridItem>

      <GridItem
        rowStart={{ sm: 1 }}
        rowEnd={{ sm: 2 }}
        colStart={{ sm: 'main', md: 'main', xl: 3 }}
        colEnd={{ sm: 8, md: 11 }}
        className="hidden justify-end sm:flex md:block"
      >
        <div className="flex aspect-square w-full max-w-96 items-center overflow-hidden rounded-lg md:max-w-none">
          <Image src={imageSrc} width="600" height="600" priority />
        </div>
      </GridItem>

      <GridItem
        rowStart={{ sm: 1 }}
        rowEnd={{ sm: 2 }}
        colStart={{ sm: 7, md: 9, lg: 10 }}
        colEnd={{ sm: 'main', md: 13, lg: 14 }}
        className="hidden flex-col items-start justify-around sm:flex"
      >
        {[
          { src: '/dha/app-sleep.png', width: 445, height: 411 },
          { src: '/dha/app-readiness.png', width: 537, height: 411 },
        ].map(({ src, width, height }) => (
          <div
            key={src}
            className="w-full max-w-48 overflow-hidden rounded-lg md:max-w-none"
          >
            <Image src={src} width={width} height={height} />
          </div>
        ))}
      </GridItem>
    </LayoutGrid>
  );
};

export default EmailLayout;
