import { emailAdded, orderAdded } from '@/lib/order/orderSlice';
import { ValidateEmailResponseGql } from '@/queries/ValidateEmail';
import { Address } from '@/types';
import { getCookie } from '@/utils/cookie';
import { logToDataDog } from '@/utils/logToDatadog';
import { ParsedSku } from '@/utils/parseSku';

import { mocks } from '../../../mocks/apiMocks';
import {
  LOGIN_METRIC_NAME,
  MetricStep,
} from '../components/ui/EmailForm/const';
import { getEmailToken } from './emailTokenHelper';

interface HandleEmailValidationParams {
  email: string;
  partnerConfig: {
    validateUser: boolean;
    completedStep: string;
    successStep: string;
    invalidStep: string;
  };
  validateEmail: (params: {
    email: string;
    emailToken?: string | null;
  }) => Promise<{ data: any }>;
  metricIncrement: (
    name: string,
    options: { step: MetricStep; partner: string },
  ) => Promise<void>;
  dispatch: (action: any) => void;
  push: (path: string) => void;
  partner: string;
}

export async function handleEmailValidation({
  email,
  partnerConfig,
  validateEmail,
  metricIncrement,
  dispatch,
  push,
  partner,
}: HandleEmailValidationParams) {
  const emailToken = getEmailToken();
  const MOCK_DATA = getCookie('mock')?.toUpperCase();
  if (partnerConfig.validateUser) {
    let response: ValidateEmailResponseGql = { data: {}, errors: undefined };
    if (MOCK_DATA) {
      //@ts-ignore
      response.data = mocks[MOCK_DATA].email;
    } else {
      response = await validateEmail({ email, emailToken });
    }
    if (
      response?.data?.eligible &&
      response?.data?.netsuiteInternalId &&
      response?.data?.shippingAddress
    ) {
      await metricIncrement(LOGIN_METRIC_NAME, {
        step: MetricStep.EMAIL_VALIDATION_SUCCESS,
        partner,
      });
      dispatch(
        orderAdded({
          email,
          netsuiteOrderId: response.data.netsuiteOrderId! ?? null,
          netsuiteInternalId: response.data.netsuiteInternalId!,
          shippingAddress: response.data.shippingAddress as Address,
          selectedSku:
            response.data.orderComplete && response.data.skus?.length
              ? new ParsedSku(response.data.skus[0]!).withoutEnterpriseCode()
              : '',
          agreesToShare: response.data.orderComplete ? false : undefined,
        }),
      );
      if (response?.data.orderComplete) push(partnerConfig.completedStep);
      else push(partnerConfig.successStep);
    } else {
      await metricIncrement(LOGIN_METRIC_NAME, {
        step: MetricStep.EMAIL_VALIDATION_ERROR,
        partner,
      });
      await logToDataDog('auth', 'EOP Email Validation error', {
        partner,
        emailToken: emailToken,
        error: response.errors ? response.errors[0].message : null,
      });
      push(partnerConfig.invalidStep);
    }
  } else {
    dispatch(emailAdded(email));
    push(partnerConfig.successStep);
  }
}
