import { logToDataDog } from './logToDatadog';

/**
 * Decodes a JWT token and returns its payload as a JSON object.
 * @param idToken The JWT token to decode.
 * @returns The decoded payload, or null if the token is invalid.
 */
export const decodeToken = (
  idToken: string | null,
): Record<string, any> | null => {
  if (!idToken) return null;

  try {
    const [, dataPart] = idToken.split('.');
    if (!dataPart) throw new Error('Invalid token format');
    return JSON.parse(atob(dataPart));
  } catch (error) {
    logToDataDog('auth', 'Error decoding Auth token', {
      error: JSON.stringify(error),
    });
    return null;
  }
};

/**
 * Extracts the email from a decoded token.
 * @param token The JWT token to decode.
 * @returns The email address if present, or an empty string.
 */
export const decodeTokenToEmail = (token: string): string => {
  const decoded = decodeToken(token);

  if (decoded && typeof decoded === 'object' && 'sub' in decoded) {
    return decoded.sub as string;
  }

  return '';
};
