const RING_TYPE_CODE = 'JZ90';

export class ParsedSku {
  sku: string;
  skuItems: string[];
  constructor(sku: string) {
    this.sku = sku;
    this.skuItems = sku.split('-');
  }

  isRing() {
    return this.sku.includes(RING_TYPE_CODE);
  }

  withoutEnterpriseCode() {
    if (!this.isRing()) {
      return this.sku;
    }

    // Check if the SKU has an enterprise prefix (e.g., NT- or MIL-)
    if (
      this.skuItems.length > 2 &&
      !this.skuItems[0].includes(RING_TYPE_CODE)
    ) {
      return this.skuItems.slice(1).join('-');
    }

    // Return the original SKU for unprefixed ring SKUs
    return this.sku;
  }
}
