import { useTranslations } from 'next-intl';

import env from '@/public-runtime-env';

const legalLinks = [
  {
    id: 'terms-and-conditions',
    label: 'footer_terms_and_conditions',
    href: '/terms-and-conditions',
  },
  {
    id: 'privacy-policy',
    label: 'footer_privacy_policy',
    href: '/privacy-policy',
  },
  {
    id: 'accessibility',
    label: 'footer_accessibility',
    href: '/accessibility',
  },
  {
    id: 'intellectual-property-notice',
    label: 'footer_ip_notice',
    href: '/intellectual-property-notice',
  },
  {
    id: 'security',
    label: 'footer_security_center',
    href: '/security',
  },
];

export default function useLegalLinks() {
  const t = useTranslations();

  const rootUrl = getRootUrl();
  const links = [];
  for (const link of legalLinks) {
    links.push({
      ...link,
      label: t(link.label),
      href: `${rootUrl}${link.href}`,
    });
  }
  return links;
}

function getRootUrl() {
  let rootUrl = env.NEXT_PUBLIC_ROOT_URL;
  if (rootUrl?.includes('localhost')) {
    rootUrl = 'https://ouraring.com';
  }
  return rootUrl;
}
