import { useState } from 'react';

import { eraseCookie } from '@/utils/cookie';

import SubmitButton from '../SubmitButton';

const NotFound = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const resetEmailForm = () => {
    setLoading(true);
    eraseCookie('session');

    //needed to ensure the auth is reinstantiated
    window.location.replace('/');
  };

  return (
    <SubmitButton
      loading={loading}
      onClick={() => resetEmailForm()}
      full
      type="submit"
    >
      Try another email
    </SubmitButton>
  );
};
export default NotFound;
