import { IMAGE_FORMATS, IMAGE_SRC_PREFIXES } from '../const';
import type { ImageFormat } from '../types';

/**
 * getSrcUrl constructs an image source url from the file name
 * (and an optional format).
 *
 * It will additionally strip the src URL of common prefixes used in this codebase
 * that will not work with the updated IMGIX source.
 *
 * URLs in IMGIX should be constructed relative to the root directory of our
 * S3 bucket, without a leading backslash.
 *
 * For example:
 *   product/my-image.jpg
 *   home-page/my-other-image.png
 */

const getSrcUrl = (src: string, defaultFormat: ImageFormat = 'jpg'): string => {
  if (!src) return '';
  if (typeof src !== 'string') return '';

  // remove the AWS/Imgix prefix
  const prefix = getPrefix(src);
  const relativeSrc = src.substring(prefix.length);

  // remove URL Params
  const relativePath = relativeSrc.split('?')[0];

  // remove the leading slash
  const path = relativePath.startsWith('/')
    ? relativePath.substring(1)
    : relativePath;

  // ensure we have the file format extension
  const [last] = path.split('.').slice(-1);
  const includesFormat = IMAGE_FORMATS.includes(last as ImageFormat);

  return includesFormat ? path : `${path}.${defaultFormat}`;
};

export function getPrefix(url: string): string {
  for (const prefix of IMAGE_SRC_PREFIXES) {
    if (url.startsWith(prefix)) {
      return prefix;
    }
  }

  return '';
}

export default getSrcUrl;
