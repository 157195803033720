const validateEmailMocks = {
  VALID_USER: {
    netsuiteOrderId: 'SO-123456',
    netsuiteInternalId: '1234567',
    shippingAddress: {
      address: '123 Test St',
      city: 'Austin',
      state: 'TX',
      postal: '78721',
      country: 'United States',
    },
    emailToken: 'test-email-token',
    orderComplete: false,
    eligible: true,
  },
  INVALID_USER: null,
  ORDER_COMPLETED: {
    netsuiteOrderId: 'SO-123456',
    netsuiteInternalId: '1234567',
    shippingAddress: {
      address: '123 Test St',
      city: 'Austin',
      state: 'TX',
      postal: '78721',
      country: 'United States',
    },
    skus: ['NT-JZ90-54144-06'],
    emailToken: 'test-email-token',
    orderComplete: true,
    eligible: true,
  },
};

const submitMocks = {
  SUBMIT_SUCCESS: {
    data: {
      status: 'success',
    },
    errors: null,
  },
  SUBMIT_ERROR: {
    data: null,
    errors: [{ message: 'Error' }],
  },
};

export const mocks = {
  HAPPY_PATH: {
    email: validateEmailMocks.VALID_USER,
    submit: submitMocks.SUBMIT_SUCCESS,
  },
  INVALID_USER: {
    email: validateEmailMocks.INVALID_USER,
    submit: submitMocks.SUBMIT_ERROR,
  },
  SUBMIT_ERROR: {
    email: validateEmailMocks.VALID_USER,
    submit: submitMocks.SUBMIT_ERROR,
  },
  ORDER_COMPLETE: {
    email: validateEmailMocks.ORDER_COMPLETED,
    submit: submitMocks.SUBMIT_SUCCESS,
  },
};
