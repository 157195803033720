import request, { gql } from 'graphql-request';

import { GraphQLEndpoint } from './endpoint';
import { GqlError } from './gqlTypes';
import { ValidateEmailResponse } from './types/graphql';
import queryClient from './utils/queryClient';

export const query = gql`
  query ValidateEmail($email: String!, $emailToken: String) {
    enterprise {
      validateEmail(email: $email, emailToken: $emailToken) {
        eligible
        netsuiteInternalId
        netsuiteOrderId
        orderComplete
        shippingAddress {
          fname
          lname
          address
          address2
          city
          state
          postal
          country
          phone
        }
        skus
      }
    }
  }
`;

export interface ValidateEmailResponseGql {
  data: ValidateEmailResponse;
  errors?: GqlError[];
}

export function withQueryKey(req: { email: string; emailToken?: any }) {
  return {
    key: ['validateEmail', req.email, req.emailToken],
    fetch: async () => {
      const response = await request(GraphQLEndpoint, query, req)
        .then((data: any) => ({
          data: data.enterprise.validateEmail,
          errors: null,
        }))
        .catch(({ response: { data, errors } }) => ({ data, errors }));
      return response as ValidateEmailResponseGql;
    },
  };
}

export async function validateEmail(req: {
  email: string;
  emailToken?: string | null;
}): Promise<ValidateEmailResponseGql> {
  const { key, fetch } = withQueryKey(req);

  return await queryClient.fetchQuery(key, fetch);
}
