import { env } from 'next-runtime-env';

import { logToDataDog } from './logToDatadog';

const API_BASE = '/api/metrics';
const PREFIX = 'web_eop';

/**
 * Increment the metric count for a given metric handle.
 * @param name Name of the metric to be incremented.
 * @param tags Optional object of tags to be attached to metrics.
 */
const increment = (
  handle: string,
  tags: Record<string, unknown> = {},
): void => {
  const NEXT_PUBLIC_METRICS_DEBUG = env('NEXT_PUBLIC_METRICS_DEBUG');
  if (NEXT_PUBLIC_METRICS_DEBUG !== 'true') {
    fetch(`${API_BASE}/increment`, {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ handle, tags }),
      method: 'POST',
    }).catch((error: Error) => {
      const envLogger = logToDataDog;
      envLogger('metrics', 'Error sending metrics increment request', {
        error,
      });
    });
  } else {
    console.log('metrics reporter increment call', '\n', {
      handle,
      tags,
    });
  }
};

export async function metricSuccess(name: string, payload = {}) {
  increment(`${PREFIX}_${name}.success`, {
    ...payload,
  });
}

export async function metricError(name: string, payload = {}) {
  increment(`${PREFIX}_${name}.error`, { ...payload });
}

export async function metricRequest(name: string, payload = {}) {
  increment(`${PREFIX}_${name}.request`, { ...payload });
}

export async function metricIncrement(name: string, payload = {}) {
  increment(`${PREFIX}_${name}`, { ...payload });
}
