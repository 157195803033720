export interface PartnerConfig {
  validateUser: boolean;
  invalidStep: string;
  successStep: string;
  completedStep: string;
}

export const config: Record<string, PartnerConfig> = {
  generic: {
    validateUser: false,
    invalidStep: '/',
    successStep: '/',
    completedStep: '/',
  },
  essence: {
    validateUser: true,
    invalidStep: '/invalid-email',
    successStep: '/select',
    completedStep: '/summary',
  },
  //add more configs for each "tenant" or "partner"...
};
