import { env } from 'next-runtime-env';

export const NEXT_PUBLIC_OURA_ENV = env('NEXT_PUBLIC_OURA_ENV');

// Don't send OTP emails (or require OTP) for non ouraring.com emails in sandbox / staging
// This will help us ensure we don't accidentally leak OTP emails externally in lower envs

const PRODUCTION = NEXT_PUBLIC_OURA_ENV === 'production';
const OURA_EMAIL = 'ouraring.com';
export const shouldAuthenticate = (email: string | null) => {
  if (!PRODUCTION && email && !email.includes(OURA_EMAIL)) {
    return false;
  } else return true;
};
