import { env } from 'next-runtime-env';

const vars = [
  'NEXT_PUBLIC_VERSION',
  'NEXT_PUBLIC_ROOT_URL',
  'NEXT_PUBLIC_OURA_ENV',
  'NEXT_PUBLIC_AUTH_CLIENT_ID',
  'NEXT_PUBLIC_AUTH_REDIRECT_URI',
  'NEXT_PUBLIC_AUTH_ENDPOINT',
  'NEXT_PUBLIC_AUTH_TOKEN_ENDPOINT',
  'NEXT_PUBLIC_APP_ASSET_PREFIX',
  'NEXT_PUBLIC_METRICS_DEBUG',
  'NEXT_PUBLIC_AUTH_OFF',
  'NEXT_PUBLIC_DD_LOG_HANDLER',
  'NEXT_PUBLIC_LOG_LEVEL',
  'NEXT_PUBLIC_METRICS_DEBUG',
  'NEXT_PUBLIC_LOCAL_OVERRIDE_COUNTRY',
  'NEXT_PUBLIC_IMGIX_HEALTH_DOMAIN',
  'NEXT_PUBLIC_IMGIX_HEALTH_URL_TOKEN',
  'NEXT_PUBLIC_STATSIG_CLIENT_KEY',
  'NEXT_PUBLIC_ECOM_API_ENDPOINT',
  'NEXT_PUBLIC_SEGMENT_KEY',
] as const;
type PublicEnvVar = (typeof vars)[number];
const _env = {} as Record<PublicEnvVar, string | undefined>;
for (const name of vars) _env[name] = env(name);
export default _env;
