/*A "slice" is a collection of Redux reducer logic and actions for a single feature in your app, 
typically defined together in a single file. The name comes from splitting up the root Redux state
 object into multiple "slices" of state. */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { type Order } from '@/types/Order';

import { RootState } from '../store';

export const initialState: Order = {
  agreesToShare: undefined,
  email: '',
  selectedSku: '',
  shippingAddress: {
    fname: '',
    lname: '',
    address: '',
    city: '',
    country: '',
    state: '',
    postal: '',
  },
  netsuiteOrderId: '',
  netsuiteInternalId: '',
};

/*Reducers */
const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    initializeOrder() {},

    //we encourage naming reducers as past-tense "this happened" names like emailAdded, because we're describing
    //"an event that occurred in the application"
    emailAdded: {
      reducer(state, action: PayloadAction<Order>) {
        state.email = action.payload.email;
      },
      prepare(email: string) {
        return {
          payload: { email, selectedSku: '' },
        };
      },
    },
    skuSelected: {
      reducer(state, action: PayloadAction<Order>) {
        state.selectedSku = action.payload.selectedSku;
      },
      prepare(selectedSku: string) {
        return {
          payload: { selectedSku },
        };
      },
    },
    submittedSharing: {
      reducer(state, action: PayloadAction<Order>) {
        state.agreesToShare = action.payload.agreesToShare;
      },
      prepare(agreesToShare: Order['agreesToShare']) {
        return {
          payload: { agreesToShare },
        };
      },
    },
    orderAdded: {
      reducer(state, action: PayloadAction<Order>) {
        Object.assign(state, action.payload);
      },
      prepare(order: Order) {
        return { payload: { ...order } };
      },
    },
    resetOrder: {
      reducer(state, action: PayloadAction<Order>) {
        Object.assign(state, action.payload);
      },
      prepare() {
        return { payload: initialState };
      },
    },
  },
});

/*Actions */
export const {
  initializeOrder,
  emailAdded,
  submittedSharing,
  skuSelected,
  orderAdded,
  resetOrder,
} = orderSlice.actions;

/*Selectors */
export const selectOrder = (state: RootState) => state.order;

export default orderSlice.reducer;
