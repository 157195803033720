export enum Event {
  CTAClicked = 'CTA Clicked',
  LinkClicked = 'link_clicked',
  PageScrolled = 'Page Scrolled',
  PlaceOrderButtonClicked = 'Place Order Button Clicked',
  Test = 'Test',
}

export const EventType = { ...Event };

enum AnalyticsName {
  ManualSizeSelected = 'Manual Size Selected',
  OrderCompleted = 'Order Completed',
  PageViewed = 'Page View',
  PageScrolled = 'Page Scrolled',
  PlaceOrderButtonClicked = 'User Clicked Place Order Button',
}

export const EventName = { ...AnalyticsName };

export interface PayloadItems {
  action?: string;
  analytics_category?: string;
  cta?: string;
  email?: string;
  location?: string;
  page?: string;
  path?: string;
  type?: typeof EventType;
}

export interface BaseDataLayer {
  event: AnalyticsName | typeof EventType;
}
export interface DataLayer extends BaseDataLayer {
  eventPath?: string;
  eventAttributes?: PayloadItems;
}
