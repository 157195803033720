import dynamic from 'next/dynamic';
import { type HTMLProps } from 'react';

import { SvgName } from './types';

// map of svg names to img file name
const SVG_IMG: Record<SvgName, string> = {
  essence: 'essence-logo',
  'green-circle-check': 'green-circle-check',
};

const getSvgImg = (name: SvgName): JSX.Element | null => {
  const svgImgFile = SVG_IMG[name];

  if (!svgImgFile) {
    console.error(`ERROR: missing SVG name ${name}`);
    return null;
  }

  const SvgImg = dynamic<HTMLProps<SVGElement>>(
    () => import(`./img/${svgImgFile}.svg`),
  );

  return <SvgImg />;
};

export default getSvgImg;
