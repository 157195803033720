import { HTMLProps, type ReactNode } from 'react';

import getSvgImg from './getSvgImg';
import type { SvgName } from './types';

export interface SvgProps extends HTMLProps<HTMLDivElement> {
  readonly name: SvgName;
}

const Svg = ({ name, ...props }: SvgProps): ReactNode => {
  const SvgImg = getSvgImg(name);

  if (SvgImg) {
    return <div {...props}>{SvgImg}</div>;
  }

  return null;
};

export default Svg;
